<template>
    <div ref="scroller" v-if="item" class="zt-page-content" style="min-width:1280px" @scroll="scroll">
        <div>
            <div class="zt-block good-block">
                <div class="img-viewer">
                    <div v-if="item.photo_render && item.photo_render.length>0" class="img-carousel">
                        <ItemImages :imgs="item.photo_render" :preUrl="imgUrl" width="400px"></ItemImages>
                    </div>
                </div>
                <div class="good-info">
                    <div class="name ellipsis">{{item.name}}</div>
                    <div class="feature-wrap">
                        <label>{{item.style.name}}</label>
                        <span>|</span>
                        <label>{{item.cat.name}}</label>
                        <span>|</span>
                        <label v-for="(item,idx) in item.dimension" :key="idx">{{item}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <!-- <label>{{item.size.len}}mm*{{item.size.wlen}}mm*{{item.size.height}}mm</label> -->
                    </div>
                    <div class="design">
                        <div class="tit">设计理念：</div>
                        <p>{{item.design_idea}}</p>
                    </div>
                    <div class="data-wrap">
                        <div class="data-item">
                            <span>--</span><b>累计点击</b>
                        </div>
                        <div class="data-item">
                            <span>--</span><b>累计下载</b>
                        </div>
                        <div class="data-item">
                            <span>--</span><b>第三方平台上架数</b>
                        </div>
                    </div>
                </div>
            </div>
            <compWrap v-if="item.design_comp" :design_comp="item.design_comp" :type="'_bqgm'"></compWrap>
            <div class="zt-block" style="padding-bottom:40px">
                <!-- <div class="pic-block">
                    <div ref="wrap" class="pic-tab-header-wrap">
                        <div ref="tabHeader" class="pic-tab-header flex" :class="{fixed: fixed, disabledFixed: disabledFixed}">
                            <div :class="['tab-item', {active: tabIndex === idx}]" @click="changeTab(idx)"
                                v-for="(item,idx) in tabList" :key="idx"
                            >{{item.name}}</div>
                        </div>
                    </div>
                    
                    <div class="pic-tab-body" ref="picBody">
                        <div v-show="tabIndex===0" class="pic-wrap">
                            <div class="pic-wrap-left-slot">
                                <div class="pic-wrap-left" :class="{fixed: fixed, disabledFixed: disabledFixed}">
                                    <div :class="{active: imgTabIndex === 0}" @click="changeImgIndex(0)">
                                        <i></i><span>标高图</span>
                                    </div>
                                    <div class="line"></div>
                                    <div :class="{active: imgTabIndex === 1}" @click="changeImgIndex(1)">
                                        <i></i><span>素材图</span>
                                    </div>
                                </div>
                            </div>
                            <div class="pic-wrap-main">
                                <div class="name ellipsis">{{item.name}}</div>
                                <div v-if="item.size" class="size">{{item.size.len}}mm*{{item.size.wlen}}mm*{{item.size.height}}mm</div>
                                <div class="tit" ref="bg0">标高图</div>
                                <div v-if="item.photo_size && item.photo_size.length>0" class="img-list">
                                    <img v-for="(item,idx) in item.photo_size" :key="idx" 
                                        :src="(imgUrl || '')+item">
                                </div>
                                <div v-else class="img-list">
                                    <img src="../../../assets/images/desgin/zwt.png" alt="">
                                </div>
                                <div class="tit" ref="sc0">素材图</div>
                                <div v-if="item.photo_story && item.photo_story.length>0" class="img-list">
                                    <img v-for="(item,idx) in item.photo_story" :key="idx" 
                                        :src="(imgUrl || '')+item">
                                </div>
                                <div v-else class="img-list">
                                    <img src="../../../assets/images/desgin/zwt.png" alt="">
                                </div>
                            </div>
                            
                        </div>
                        <div v-show="tabIndex>0" class="pic-wrap">
                            <div class="pic-wrap-left-slot">
                                <div class="pic-wrap-left" :class="{fixed: fixed, disabledFixed: disabledFixed}">
                                    <div :class="{active: imgTabIndex === 0}" @click="changeImgIndex(0)">
                                        <i></i><span>标高图</span>
                                    </div>
                                    <div class="line"></div>
                                    <div :class="{active: imgTabIndex === 1}" @click="changeImgIndex(1)">
                                        <i></i><span>素材图</span>
                                    </div>
                                </div>
                            </div>
                            <div v-for="(item,idx) in item.accessory" :key="idx" class="pic-wrap-main">
                                <div v-if="tabIndex===(idx+1)">
                                    <div class="name ellipsis">{{item.name}}</div>
                                    <div v-if="item.size" class="size">{{item.size.len}}mm*{{item.size.wlen}}mm*{{item.size.height}}mm</div>
                                    <div class="tit" :ref="'bg' + (idx + 1)">标高图</div>
                                    <div v-if="item.photo_size && item.photo_size.length>0" class="img-list">
                                        <img v-for="(item,idx) in item.photo_size" :key="idx" 
                                            :src="(imgUrl || '')+item">
                                    </div>
                                    <div v-else class="img-list">
                                        <img src="../../../assets/images/desgin/zwt.png" alt="">
                                    </div>
                                    <div class="tit" :ref="'sc' + (idx + 1)">素材图</div>
                                    <div v-if="item.photo_story && item.photo_story.length>0" class="img-list">
                                        <img v-for="(item,idx) in item.photo_story" :key="idx" 
                                            :src="(imgUrl || '')+item">
                                    </div>
                                    <div v-else class="img-list">
                                        <img src="../../../assets/images/desgin/zwt.png" alt="">
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div v-if="item &&item.photo_cad && item.photo_cad.length>0" class="cad-block">
                        <span>设计CAD文件:</span>
                        <img src="../../../assets/images/desgin/icon_cad.png">
                        <label v-if="item && item.photo_cad">共{{item.photo_cad.length}}份</label>
                        <a :href="imgUrl+item.photo_cad[0]" class="btn-wrap">
                            <el-button size="small" type="primary" plain>下载</el-button>
                        </a>
                        
                    </div>
                </div> -->
                <ItemBigImg v-if="item && item.id" ref="child" :item="item"></ItemBigImg>
                <div v-if="item &&item.photo_cad" class="cad-block">
                    <span>设计CAD文件:</span>
                    <img src="../../../assets/images/desgin/icon_cad.png">
                    <label v-if="item && item.photo_cad">共{{item.photo_cad.length}}份</label>
                    <a v-if="item.photo_cad.length>0" :href="imgUrl+item.photo_cad[0]" class="btn-wrap">
                        <el-button size="small" type="primary" plain>下载</el-button>
                    </a>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { throttle } from 'lodash'
    import { IMG_URL_PRE } from '@/config';
    import { getSelfId } from '@/service/factory';
    import ItemImages from '@/components/ItemImages.vue';
    import compWrap from './compWrap.vue';
    import ItemBigImg from '@/components/factory/ItemBigImg.vue';
    export default {
        components: {
            ItemImages,compWrap,
            ItemBigImg
        },
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                popVisible: false,
                popTitle: null,
                tabList:[
                    {name:"主作品"},
                ],
                tabIndex:0,
                imgTabIndex: 0,
                item:null,
                fixed: false,
                disabledFixed: false
            }
        },
        created:function() {
            // this.scroll = throttle(this.scroll, 50)
            this.data();
        },
        methods: {
            scroll(e) {
                this.$refs.child.scroll(e);
            },
            // changeImgIndex(i) {
            //     const scroller = this.$refs.scroller
            //     this.imgTabIndex = i
            //     // console.log(this.$refs[`bg${this.tabIndex}`].offsetTop)
            //     if (i === 0) {
            //         if (this.tabIndex > 0) {
            //             scroller.scrollTop = this.$refs[`bg${this.tabIndex}`][0].offsetTop - 110
            //         } else {
            //             scroller.scrollTop = this.$refs[`bg${this.tabIndex}`].offsetTop - 110
            //         }
                    
            //     } else {
            //         if (this.tabIndex > 0) {
            //             scroller.scrollTop = this.$refs[`sc${this.tabIndex}`][0].offsetTop - 110
            //         } else {
            //             scroller.scrollTop = this.$refs[`sc${this.tabIndex}`].offsetTop - 110
            //         }
            //     }
            // },
            // scroll(e) {
                
            //     const scroller = e.target
            //     const wrap = this.$refs.wrap
            //     const tabHeader = this.$refs.tabHeader
            //     // console.log(scroller.scrollTop, wrap.offsetTop)
            //     // 主副tab滑到顶部fix
            //     if (scroller.scrollTop > wrap.offsetTop) {
            //         if (!this.fixed) {
            //             this.fixed = true
            //         }
            //     } else {
            //         if (this.fixed) {
            //             this.fixed = false
            //         }
            //     }
            //     const picBody = this.$refs.picBody;
            //     // 素材图向上划出时隐藏tab
            //     if (scroller.scrollTop > picBody.offsetTop + picBody.offsetHeight - 470) {
            //         if (!this.disabledFixed) {
            //             this.disabledFixed = true
            //         }
            //     } else {
            //         if (this.disabledFixed) {
            //             this.disabledFixed = false
            //         }
            //     }
            //     //标高图素材图tab切换
            //     const sc = this.tabIndex === 0?this.$refs.sc0:this.$refs[`sc${this.tabIndex}`][0]
            //     if (scroller.scrollTop > sc.offsetTop - 110) {
            //         if (this.imgTabIndex !== 1) {
            //             this.imgTabIndex = 1
            //         }
            //     } else {
            //         if (this.imgTabIndex !== 0) {
            //             this.imgTabIndex = 0
            //         }
            //     }
            // },
            data() {
                getSelfId(this.$route.params.id).then(rst=>{
                    if (rst) {
                        this.item=rst;
                        if (this.item.accessory && this.item.accessory.length>0) {
                            this.item.accessory.forEach(e => {
                                this.tabList.push({name:e.name});
                            });
                        }
                    }
                    console.log(this.item);
                });
            },
            goto(path){
                this.$router.push(path);
            },
            // changeTab: function (i) {// 选项卡
            //     this.tabIndex = i
            //     const scroller = this.$refs.scroller
            //     const wrap = this.$refs.wrap
            //     // console.log(wrap.offsetTop)
            //     // scroller.scrollTop = wrap.offsetTop
            //     setTimeout(() => {
            //         scroller.scrollTop = wrap.offsetTop
            //     },100)
            // }
        }
    }
</script>

<style src="../style.css"></style>
<style scoped lang="less">
    .zt-page-content {
        position: relative;
        .good-block {
            padding: 64px 84px 45px;
            display: flex;
            .img-viewer {
                margin-right: 45px;
                .img-preview {
                    width: 420px;
                    height: 420px;
                    border: 1px dashed #ccc;
                    img {
                        width: 100%;
                        height:100%;
                        display: block;
                    }
                }
                .img-thumb-list {
                    margin-top: 10px;
                    font-size: 0;
                    overflow: auto;
                    width: 420px;
                    white-space: nowrap;
                    .img-wrap {
                        display: inline-block;
                        width: 70px;
                        height: 70px;
                        border: 1px solid #ccc;
                        cursor: pointer;
                        margin-right: 18px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
            .good-info {
                font-size: 14px;
                .name {
                    color: #292929;
                    font-size: 24px;
                    margin-left: 16px;
                    font-weight: bold;
                }
                .feature-wrap {
                    color: #ccc;
                    margin: 8px 16px 54px;

                    span {
                        margin: 0 24px;
                    }
                }
                .design {
                    background-color: rgba(#2BBAAE, 0.05);
                    border-radius:4px;
                    padding: 8px 16px;
                    .tit {
                        color: #4D4D4D;
                        font-size: 16px;
                        font-weight: bold;
                        margin-bottom: 16px;
                    }
                }
                .data-wrap {
                    margin-top: 108px;
                    display: flex;
                    .data-item {
                        width:128px;
                        height:71px;
                        background:rgba(255,145,0,0.1);
                        border-radius:4px;
                        margin-right: 32px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        span {
                            color: #F66F6A;
                            font-size: 24px;
                        }
                        b {
                            color: #4D4D4D;
                            font-weight: bold;
                        }
                    }
                    
                }
            }
        }
        .cad-block {
            width: 1110px;
            margin: 0 auto;
            height: 157px;
            padding: 40px 70px;
            display: flex;
            border:1px solid rgba(204,204,204,1);
            box-shadow:0px 3px 6px rgba(0,0,0,0.16);
            border-radius:4px;
            margin-top: 30px;
            span {
                margin-right: 15px;
                color: #4D4D4D;
                font-size: 20px;
                font-weight: bold;
            }
            label {
                color: #ccc;
                margin: 0 58px 0 5px;
                align-self: flex-end;
            }
            .btn-wrap {
                align-self: flex-end;
            }
        }
    }
</style>